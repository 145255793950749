import React from 'react';
import { Helmet } from 'react-helmet';
import icon from '../assets/images/Favicon/favicon.ico';
import socialImage from '../assets/images/onesource_social_crop_center.png';

export default function SEO({ children, location, description, title, image }) {
  return (
    <Helmet
      titleTemplate="%s | One Source Coalition"
      defaultTitle="One Source Coalition"
    >
      <html lang="en" />
      {title && <title>{title}</title>}
      <link rel="icon" href={icon} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
      <meta
        name="description"
        content="Key policies needed to reduce plastic waste"
      />
      <meta
        name="keywords"
        content="plastic waste, recycling, circularity, recycling policy, extended producer responsibility, corporate sustainability, circularity policy, Congress, ocean pollution, plastic pollution, environmental justice, international leadership, UN Treaty on Plastic"
      />
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={socialImage} />
      {title && <meta property="og:title" content={title} key="ogtitle" />}
      <meta
        property="og:site_name"
        content="One Source Coalition"
        key="ogsitename"
      />
      <meta
        property="og:description"
        content="Key policies needed to reduce plastic waste"
        key="ogdesc"
      />
      {children}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-Z7F5TZ4Y60"
      />
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-Z7F5TZ4Y60');`}
      </script>
    </Helmet>
  );
}
